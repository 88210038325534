<template>
    <button type="button" :class="actionClass" :style="action.style" :disabled="disabled" :title="action.label"
            v-on:click="$router.push({name: action.routename, params: (action.routeparamsfield ? data[action.routeparamsfield] : action.routeparams)})">
        <font-awesome-icon v-if="action.icon && !action.iconright" :icon="action.icon" size="sm" :class="marginClass"/>
        <template v-if="!action.compact">{{ action.label }}</template>
        <font-awesome-icon v-if="action.icon && action.iconright" :icon="action.icon" size="sm" :class="marginClass"/>
    </button>
</template>

<script>

export default {
    name: "RouteAction",
    props: ["action", "disabled", "data"],
    data() {
        return {
            actionClass: 'btn btn-sm ' + this.action.class,
            marginClass: (this.action.icon && !this.action.compact ? (this.action.iconright ? 'ml-2' : 'mr-2') : ''),
        }
    },
}
</script>

<style scoped>
</style>
