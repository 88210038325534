<template>
    <div class="custom-switch">
        <input type="checkbox" :id="action.name" class="custom-control-input" :disabled="disabled"
               v-model="action.value"
               v-on:change="onChange">
        <label class="custom-control-label" :for="action.name">{{ action.label }}</label>
    </div>
</template>

<script>
export default {
    name: "SwitchAction",
    props: {
        action: {type: Object, default: null},
        disabled: {type: Boolean, default: false},
        forwardEvents: {type: Boolean, default: false},
    },
    created() {
        this.action.value = ('value' in this.action ? this.action.value : ('default' in this.action ? this.action.default : false));
    },
    methods: {
        onChange() {
            if (this.forwardEvents) {
                this.$emit('forward-event', {event: this.action.name, data: this.action.value});
            } else {
                this.$emit(this.action.name, this.action.value);
            }
        },
    }
}
</script>

<style scoped>
</style>
